import { createStore } from 'react-pack-mule';
// zustand devtools makes use of redux chrome extension
import { devtools } from 'zustand/middleware';
import { createBrowserHistory } from 'history';
import { camelCase } from 'lodash';

import mapKeyValues from 'lib-frontend-shared/src/helpers/mapKeyValues';
import getInitialState from './getInitialState';

export const history = createBrowserHistory();

export const {
  useGlobalStates,
  getStates,
  setStates,
  updateStates,
  createPropUpdater,
} = createStore(null, devtools(getInitialState));

export const resetStore = (authStates) => setStates({
  ...getInitialState(),
  auth: authStates,
});

export const {
  updateGlobal,
  updateCities: updateCitiesByCountry,
  updateStates: updateStatesByCountry,
  updateCitiesByState,
  updateAreas,
  updateAuth,
  updateDialogToDisplay,
  updateLoading,
  updateRoute,
  updateToast,
  updateCachedEntities,

  updateApiClients,
  updateConnectors,
  updateCarrier,
  updateClicknShipService,
  updateCarrierCapacity,
  updateCommunicationSettings,
  updateShipmentCommunicationSettings,
  updateReverseShipmentCommunicationSettings,
  updateReturnRequestCommunicationSettings,
  updateLocationsList,
  updateLocation,
  updateNetwork,
  updateManifestList,
  updateManifest,
  updateReports,
  updateReturnRequestDetail,
  updateReturnRequestList,
  updateReturnRequestSettings,
  updateReverseShipment,
  updateReverseShipmentList,
  updateReverseShipmentRuleList,
  updateRule,
  updateRuleList,
  updateServiceLevel,
  updateServiceLevelsSettings,
  updateShipment,
  updateShipmentList,
  updateTenantSettings,
  updateUsers,
  updateUserList,
  updateWebhookSettings,

  // Store app specific states
  updateCustomerList,
  updateCustomerDetail,
  updateOrderList,
} = mapKeyValues(
  [
    // shared states (or states used by shared components)
    'global',
    'cities',
    'states',
    'citiesByState',
    'auth',
    'dialogToDisplay',
    'loading',
    'route',
    'toast',
    'cachedEntities',

    // (mostly) page specific states
    'apiClients',
    'connectors',
    'carrier',
    'clicknShipService',
    'carrierCapacity',
    'communicationSettings',
    'shipmentCommunicationSettings',
    'reverseShipmentCommunicationSettings',
    'returnRequestCommunicationSettings',
    'locationsList',
    'location',
    'network',
    'manifest',
    'manifestList',
    'reports',
    'returnRequestDetail',
    'returnRequestList',
    'returnRequestSettings',
    'reverseShipment',
    'reverseShipmentList',
    'reverseShipmentRuleList',
    'rule',
    'ruleList',
    'serviceLevel',
    'serviceLevelsSettings',
    'shipment',
    'shipmentList',
    'tenantSettings',
    'users',
    'userList',
    'webhookSettings',

    // store app specific states
    'customerList',
    'customerDetail',
    'orderList',
  ],
  ({ val: prop }) => ({ key: camelCase(`update_${prop}`), val: createPropUpdater(prop) }),
);

// for debugging purpose - redux devtool is slowing down dev tools
Object.defineProperty(window, 'globalStates', {
  get: getStates,
  set() {},
});
