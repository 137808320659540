/* eslint-disable react/button-has-type */

import React, { forwardRef } from 'react';

import cls from '../helpers/cls';

import './Button.css';

const Button = ({
  color = 'normal',
  children,
  className: classes = '',
  round = false,
  size = 'md',
  type = 'button',
  ...props
}, ref) => (
  <button
    className={cls('Button', { color, round, size }, classes)}
    {...props}
    ref={ref}
    type={type}
  >
    {children}
  </button>
);

export default forwardRef(Button);
