import {
  castArray,
  uniq,
} from 'lodash';
import * as apiClient from '../api-client';
import { fetchNonExistingIds, upsertEntity } from './cached-entities';
import { getStates } from '../store';
import { reader } from '../helpers/action';

const entity = 'locations';

export const getLocationDetails = reader(
  entity,
  async ({
    locationIds = [],
    locationNames = [],
  } = {}) => {
    const { cachedEntities: { locations: existingLocations = [] } = {} } = getStates();
    if (!locationIds.length && !locationNames.length) return { locations: existingLocations };
    const castedLocationIds = uniq(castArray(locationIds).filter(Boolean));
    const castedLocationNames = uniq(castArray(locationNames).filter(Boolean));
    if (castedLocationIds.length < 1 && castedLocationNames.length < 1) {
      return { locations: existingLocations };
    }
    const nonExistingIdentifiers = fetchNonExistingIds({
      identifiers: castedLocationIds.length ? castedLocationIds : castedLocationNames,
      entity,
      identifier: castedLocationNames.length ? 'locationName' : 'locationId',
    });
    if (nonExistingIdentifiers.length > 0) {
      const { locations = [] } = await apiClient.getLocationDetails({
        ...(castedLocationNames.length ? {
          locationNames: nonExistingIdentifiers,
        } : {
          locationIds: nonExistingIdentifiers,
        }),
      });
      const { locations: updatedLocations } = upsertEntity({
        entity,
        list: locations.filter(({ locationId }) => Boolean(locationId)),
        identifier: 'locationId',
      });
      return { locations: updatedLocations };
    }
    const { cachedEntities: { locations = [] } = {} } = getStates();
    return { locations };
  },
);

export const getLocationOptions = reader(
  entity,
  async ({
    searchString,
    allFilters,
  } = {}, signal) => {
    const { locations = [] } = await apiClient.getLocations({
      page: 0,
      pageSize: 100,
      searchString,
      status: 'ACTIVE',
      sortBy: 'location_name',
      sortDirection: 'asc',
      allFilters,
      fields: [],
    }, signal);
    upsertEntity({
      entity,
      list: locations,
      identifier: 'locationId',
    });
    return locations;
  },
);
