// -- react, router -- //
import React, {
  lazy, Suspense, useEffect, useState,
} from 'react';

import { useMediaQuery } from 'react-responsive';

import {
  MuiThemeProvider,
} from '@material-ui/core/styles';

import Dialog from 'lib-frontend-shared/src/components/Dialog';
import FormButton from 'lib-frontend-shared/src/components/FormButton';
import Linear from 'lib-frontend-shared/src/components/Linear';
import PosterView from 'lib-frontend-shared/src/components/PosterView';
import Sticker from 'lib-frontend-shared/src/components/Sticker';
import Typography from 'lib-frontend-shared/src/components/Typography';

// -- custom --
import largeDecoratedBird from 'lib-frontend-shared/src/assets/images/branding/bird-decorated-large.png';
import MultiSelectAutocomplete from 'lib-frontend-shared/src/components/MultiSelectAutocomplete';
import CopyToClipboardButton from '../CopyToClipboardButton';
import Toast from '../Toast';
import useGlobalStates from '../../helpers/useGlobalStates';
import AccountSwitchDialog from './AccountSwitchDialog';
import { showDialog } from '../../actions/dialog';
import * as authActions from '../../actions/auth';
import { logout } from '../../logout';
import logo from '../../images/carriyo-logo-dark.svg';
import config from '../../config';
import { isAccountOwner } from '../../enums';
import theme from '../../theme';

const LayoutDesktop = lazy(() => import(
  /* webpackChunkName: "[LayoutDesktop]" */
  './LayoutDesktop'
));

const LayoutMobile = lazy(() => import(
  /* webpackChunkName: "[LayoutMobile]" */
  './LayoutMobile'
));

function Layout(props) {
  const {
    auth: {
      authorizing,
      accessByTenantId,
      tenantId,
      role,
    },
    global: { tenantSettings },
  } = useGlobalStates(['auth', 'global']);

  const isMobileView = useMediaQuery({ maxDeviceWidth: 760 });
  const RenderLayout = isMobileView ? LayoutMobile : LayoutDesktop;

  useEffect(() => {
    if (!authorizing && !tenantId) showDialog('accountSwitch');
  }, [authorizing, tenantId]);

  const tenantIds = Object.keys(accessByTenantId).sort();

  const [selectedTenant, setSelectedTenant] = useState(undefined);

  useEffect(() => {
    setSelectedTenant(tenantId);
  }, [tenantId]);

  if (authorizing) {
    return (
      <MuiThemeProvider theme={theme}>
        <Toast />
      </MuiThemeProvider>
    );
  }

  /* eslint-disable jsx-a11y/anchor-is-valid */
  if (tenantIds.length === 0) {
    return (
      <MuiThemeProvider theme={theme}>
        <PosterView
          header="You don’t have access to any accounts."
          type="smb"
          footer={(
            <Linear orientation="vertical" align="center" gap="sm">
              <Typography variant="para.lg">
                Please contact your administrator to invite you to an existing account or
                {' '}
                <a
                  href="https://carriyo.com/pricing"
                >
                  sign-up
                </a>
                {' '}
                to create a new account.
              </Typography>
              <Typography variant="para.sm:body">
                Please click here to
                <a
                  href=""
                  onClick={(event) => {
                    event.preventDefault();
                    logout();
                  }}
                >
                &nbsp;logout
                </a>
                .
              </Typography>
            </Linear>
          )}
        />
      </MuiThemeProvider>
    );
  }
  /* eslint-enable jsx-a11y/anchor-is-valid */

  const activeSubscription = tenantSettings.active !== false;
  const tenantOptions = Object.entries(accessByTenantId).map(([value, { name }]) => ({ label: name, value }));
  const selectedTenantName = tenantOptions.find(({ value }) => value === selectedTenant)?.label;
  const storeappSubscription = tenantSettings.storeappSubscribed !== false;

  return (
    <MuiThemeProvider theme={theme}>
      <Dialog
        open={!activeSubscription}
        showClose={false}
        size="fullscreen"
        standalone
        type="wizard"
      >
        <Linear height="25pr" orientation="horizontal" style={{ zIndex: 1 }} width="100pr">
          <img alt="Carriyo" src={logo} style={{ margin: '2vw', width: '160px' }} />
          <Sticker
            src={largeDecoratedBird}
            bottom="-66px"
            right="-66px"
            transform="scale(0.75)"
          />
        </Linear>
        <Linear
          align="center"
          gap="3xl"
          height="100pr"
          justify="center"
          orientation="vertical"
          style={{ zIndex: 2 }}
        >
          <Typography variant="h1">
            This account is locked.
          </Typography>
          <Typography align="center" variant="para.md:body">
            Your subscription has expired.
            <br />
            {`Please ${isAccountOwner(role) ? 'renew your subscription before logging in' : 'contact your account owner and retry'}.`}
          </Typography>
          <FormButton
            color="secondary"
            onClick={() => {
              if (isAccountOwner(role)) {
                window.location.href = config.accountAppBaseUrl;
              } else {
                window.location.reload();
              }
            }}
          >
            {isAccountOwner(role) ? 'Manage Account' : 'Retry'}
          </FormButton>
          <Linear align="center" gap="sm" orientation="horizontal">
            {selectedTenant && (
              <>
                <Typography color="secondary" variant="para.sm:head">
                  {`Tenant: ${selectedTenantName || selectedTenant}${selectedTenantName ? ` (${selectedTenant})` : ''}`}
                </Typography>
                <CopyToClipboardButton
                  color="secondary"
                  copyText="Copy Tenant ID"
                  iconStyle={{ height: '15px', width: '15px' }}
                  source={[selectedTenant]}
                />
              </>
            )}
          </Linear>
          {tenantIds.length > 1 && (
            <>
              <Typography align="center" variant="para.md:body">
                Or switch to another account
              </Typography>
              <MultiSelectAutocomplete
                onChange={setSelectedTenant}
                allowedOptions={tenantOptions}
                allowFreeText={false}
                rootElementProps={{ width: '360px' }}
                multiple={false}
                value={selectedTenant}
              />
              <FormButton
                color="secondary"
                disabled={selectedTenant === tenantId}
                onClick={() => authActions.setTenantId(selectedTenant)}
              >
                OK
              </FormButton>
            </>
          )}
        </Linear>
        <Linear height="25pr" />
      </Dialog>

      {/* If no storeapp subscription */}
      <Dialog
        open={!storeappSubscription}
        showClose={false}
        size="fullscreen"
        standalone
      >
        <Linear height="25pr" orientation="horizontal" style={{ zIndex: 1 }} width="100pr">
          <img alt="Carriyo" src={logo} style={{ margin: '2vw', width: '160px' }} />
          <Sticker
            src={largeDecoratedBird}
            bottom="-66px"
            right="-66px"
            transform="scale(0.75)"
          />
        </Linear>
        <Linear
          align="center"
          gap="3xl"
          height="100pr"
          justify="center"
          orientation="vertical"
          style={{ zIndex: 2 }}
        >
          <Typography variant="h1">
            {'Sorry, you don\'t have access to this app.'}
          </Typography>
          <Typography align="center" variant="para.md:body">
            Contact your system administrator to get access.
          </Typography>
          <FormButton
            color="secondary"
            onClick={() => {
              if (isAccountOwner(role)) {
                window.location.href = config.accountAppBaseUrl;
              } else {
                window.location.reload();
              }
            }}
          >
            {isAccountOwner(role) ? 'Manage Account' : 'Retry'}
          </FormButton>
          <Linear align="center" gap="sm" orientation="horizontal">
            {selectedTenant && (
              <>
                <Typography color="secondary" variant="para.sm:head">
                  {`Tenant: ${selectedTenantName || selectedTenant}${selectedTenantName ? ` (${selectedTenant})` : ''}`}
                </Typography>
                <CopyToClipboardButton
                  color="secondary"
                  copyText="Copy Tenant ID"
                  iconStyle={{ height: '15px', width: '15px' }}
                  source={[selectedTenant]}
                />
              </>
            )}
          </Linear>
          {tenantIds.length > 1 && (
            <>
              <Typography align="center" variant="para.md:body">
                Or switch to another account
              </Typography>
              <MultiSelectAutocomplete
                onChange={setSelectedTenant}
                allowedOptions={tenantOptions}
                allowFreeText={false}
                rootElementProps={{ width: '360px' }}
                value={selectedTenant}
                multiple={false}
              />
              <FormButton
                color="secondary"
                disabled={selectedTenant === tenantId}
                onClick={() => authActions.setTenantId(selectedTenant)}
              >
                OK
              </FormButton>
            </>
          )}
        </Linear>
        <Linear height="25pr" />
      </Dialog>
      {tenantId && activeSubscription && storeappSubscription && (
        <Suspense fallback={null}>
          <RenderLayout {...props} />
        </Suspense>
      )}
      <AccountSwitchDialog name="accountSwitch" standalone={!tenantId} view={isMobileView ? 'mobile' : 'desktop'} />
      <Toast />
    </MuiThemeProvider>
  );
}

export default Layout;
