import qs from 'qs';
import { identity } from 'lodash';
import axios from '../axios';
import moment from '../helpers/moment';
import config from '../config';
import { getStates } from '../store';
import { isLogoutRequested, logout } from '../logout';

export const timeZoneOffset = -1 * (new Date()).getTimezoneOffset();

export function getBackendHeadersToAdd() {
  const {
    auth: {
      accessToken,
      tenantId,
      accessByTenantId,
      carriyoUserId,
    },
  } = getStates();

  const { apiKey } = accessByTenantId[tenantId] || {};

  return {
    'x-api-key': apiKey,
    'tenant-id': tenantId,
    'x-user': carriyoUserId,
    Authorization: `Bearer ${accessToken}`,
  };
}

export function getCacheBustHeaders() {
  return {
    'Cache-Control': 'no-cache, no-store',
    Pragma: 'no-cache', // hack for cache busting on safari
    Vary: `${Math.trunc(Math.random() * 100000)}`, // hack for cache busting on safari
  };
}

const oneMinuteInMs = 1 * 60 * 1000;

const proactiveLogoutInterceptor = (requestConfig) => {
  const { auth: { expiresAt } = {} } = getStates();
  if (expiresAt && (Date.now() > expiresAt - oneMinuteInMs)) {
    logout();
    throw new Error('Session expired');
  }
  return requestConfig;
};

const browserLogPath = '/public/logs/browser-log';

const reactiveLogoutInterceptor = (error) => {
  let url;
  try {
    url = error?.config?.url ? new URL(`${(error.config.baseURL || '')}${error.config.url}`) : null;
  } catch (err) {
    // most likely a URL parsing error. Ignore and continue
  }
  if (
    error?.response?.status === 401
    && (!url || !url.pathname?.startsWith(browserLogPath)) // avoid 401 loop with browser logger
  ) {
    logout();
  }
  throw error;
};

export const carriyoClient = axios.create({
  baseURL: config.backendBaseUrl,
  headers: {
    'content-type': 'application/json',
    'user-tz': moment.tz.guess(),
  },
  paramsSerializer: (ps) => qs.stringify(ps, { arrayFormat: 'repeat' }),
});
carriyoClient.interceptors.request.use((requestConfig) => {
  if (isLogoutRequested()) return requestConfig;
  // eslint-disable-next-line no-param-reassign
  requestConfig.headers = {
    ...getBackendHeadersToAdd(),
    ...requestConfig.headers,
  };
  // if data is undefined, axios removes Content-Type header
  if (requestConfig.data === undefined) {
    // eslint-disable-next-line no-param-reassign
    requestConfig.data = {};
  }

  return requestConfig;
});
carriyoClient.interceptors.request.use(proactiveLogoutInterceptor);
carriyoClient.interceptors.response.use(identity, reactiveLogoutInterceptor);

export const documentServiceClient = axios.create({
  baseURL: config.documentsBaseUrl,
  headers: {
    'content-type': 'application/json',
    'user-tz': moment.tz.guess(),
  },
  paramsSerializer: (ps) => qs.stringify(ps, { arrayFormat: 'repeat' }),
});
export const nodeMiddlewareClient = axios.create({
  baseURL: config.middlewareBaseUrl,
  // baseURL: 'http://localhost:5000',
  headers: {
    'content-type': 'application/json',
  },
  paramsSerializer: (ps) => qs.stringify(ps, { arrayFormat: 'repeat' }),
});
export const nodeMiddlewareIOClient = axios.create({
  baseURL: config.trackingAppBaseUrl,
  headers: {
    'content-type': 'application/json',
  },
  paramsSerializer: (ps) => qs.stringify(ps, { arrayFormat: 'repeat' }),
});

const middlewareInterceptor = (requestConfig) => {
  if (isLogoutRequested()) return requestConfig;
  const {
    auth: {
      tenantId,
      accessToken,
      accessByTenantId,
      carriyoUserId,
    },
  } = getStates();

  const { apiKey } = accessByTenantId[tenantId] || {};

  Object.assign(requestConfig.headers, {
    // auth0 token
    Authorization: `Bearer ${accessToken}`,
    // api gateway key
    'x-api-key': apiKey,
    // other mandatory headers
    // eslint-disable-next-line no-prototype-builtins
    'tenant-id': requestConfig.headers?.hasOwnProperty?.('tenant-id')
      ? requestConfig.headers['tenant-id']
      : tenantId,
    'x-user': carriyoUserId,
  });
  return requestConfig;
};
documentServiceClient.interceptors.request.use(middlewareInterceptor);
documentServiceClient.interceptors.request.use(proactiveLogoutInterceptor);
documentServiceClient.interceptors.response.use(identity, reactiveLogoutInterceptor);
nodeMiddlewareClient.interceptors.request.use(middlewareInterceptor);
nodeMiddlewareClient.interceptors.request.use(proactiveLogoutInterceptor);
nodeMiddlewareClient.interceptors.response.use(identity, reactiveLogoutInterceptor);
nodeMiddlewareIOClient.interceptors.request.use(middlewareInterceptor);
nodeMiddlewareIOClient.interceptors.request.use(proactiveLogoutInterceptor);
nodeMiddlewareIOClient.interceptors.response.use(identity, reactiveLogoutInterceptor);

export const log = async (payload) => {
  try {
    const { status } = await nodeMiddlewareClient.post(browserLogPath, payload);
    if (status === 200) return true;
  } catch (err) {
    // DO NOT log with console.error, to prevent infinite cyclic logging
    // eslint-disable-next-line no-console, no-underscore-dangle
    (console._error || console.debug)(err);
    return /** @type {Error} */ (err);
  }
  return false;
};

export const toMerchantsParam = (selectedMerchantIds) => (
  selectedMerchantIds
    ? { merchant: selectedMerchantIds.length ? selectedMerchantIds : [''] }
    : {}
);
