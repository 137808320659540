import React from 'react';

import blockEvent from '../helpers/blockEvent';
import cls from '../helpers/cls';

const Form = ({
  children,
  className,
  onSubmit,
  ...rest
}) => (
  <form
    className={cls('Form', {}, className)}
    onSubmit={(event) => {
      blockEvent(event);
      if (event.currentTarget.reportValidity()) {
        onSubmit(event);
      }
    }}
    {...rest}
  >
    {children}
  </form>
);

export default Form;
