import makeActions from 'lib-frontend-shared/src/helpers/action';

import getErrorFromBEResponse from './getErrorFromBEResponse';
import * as loadingActions from '../actions/loading';
import * as toastActions from '../actions/toast';

const {
  reader: readerAction,
  writer: writerAction,
} = makeActions({
  getErrorFromBEResponse,
  loadingActions,
  toastActions,
});

export const reader = readerAction;
export const writer = writerAction;
