import React, { Fragment } from 'react';

import cls from '../helpers/cls';

import ChevronLeftIcon from '../icons/ChevronLeft';
import CircleFilledIcon from '../icons/CircleFilled';
import CloseIcon from '../icons/Close';
import MinimizeIcon from '../icons/Minimize';

import Button from './Button';
import DialogBase from './DialogBase';
import FormButton from './FormButton';
import Linear from './Linear';
import Spacer from './Spacer';
import Tooltip from './Tooltip';
import Typography from './Typography';

import './Dialog.css';

const Steps = ({ steps, selected }) => (
  <Linear
    align="center"
    justify="center"
    orientation="horizontal"
    style={{ marginBottom: '14px' }}
    width="100pr"
  >
    {[...Array(steps).keys()].map((index) => (
      <CircleFilledIcon
        fontSize="small"
        className={cls('Dialog-step', {
          prev: index < selected,
          next: index > selected,
        })}
      />
    ))}
  </Linear>
);

const Dialog = ({
  className,
  standalone = false,
  noPadding = false,

  actions = [],
  children,
  closeOnBlur = false,
  disableCloseButton = false,
  footer = {},
  hideFooter = standalone,
  hideHeader = standalone,
  isMobile,
  onClose,
  showClose = true,
  subtitle,
  title,
  toolbar = [],
  type = '',
  selectedStep = 0,
  steps = 0,

  noToolbar,

  onBack,
  onMinimize,
  ...props
}) => (
  <DialogBase
    className={cls('Dialog', { standalone, type }, className)}
    onClose={closeOnBlur ? onClose : undefined}
    {...props}
  >
    <Linear
      align="center"
      className={cls('Dialog-toolbar', { standalone, noToolbar })}
      gap="sm"
      justify="space-between"
    >
      {onBack && (selectedStep > 0) && (
        <Linear
          align="center"
          className="Dialog-backButton"
          onClick={() => onBack(selectedStep - 1)}
        >
          <ChevronLeftIcon fontSize="large" />
          <Spacer x="xs" />
          <Typography variant="para.xs" weight="medium" color="secondary">Go Back</Typography>
        </Linear>
      )}
      <Spacer flex />
      {onMinimize && (
        <Linear className={cls('Dialog-headerButtonWrapper', { type })}>
          <Button
            className={cls('Dialog-headerButton', { standalone, type })}
            onClick={onMinimize}
            round
            size="sm"
          >
            <MinimizeIcon />
          </Button>
        </Linear>
      )}
      {showClose && (
        <Linear className={cls('Dialog-headerButtonWrapper', { type })}>
          <Button
            className={cls('Dialog-headerButton', { standalone, type })}
            disabled={!showClose || disableCloseButton}
            onClick={onClose}
            round
            size="sm"
          >
            <CloseIcon />
          </Button>
        </Linear>
      )}
    </Linear>
    <div className={cls('Dialog-container', { standalone, type })}>
      {!hideHeader && (
        <div className={cls('Dialog-header', { type })}>
          <div className={cls('Dialog-titleBar', { type })}>
            {['wizard', 'centered'].includes(type) && <Spacer flex />}
            <Typography className={cls('Dialog-title', { type })} letterSpacing={['wizard', 'centered'].includes(type) ? '3xs' : 'inherit'}>
              {title}
            </Typography>
            <Spacer flex />
            {toolbar.map(({ component, key, ...rest }) => (component ? (
              <Fragment key={key}>
                {component}
              </Fragment>
            ) : (
              <Button key={key} {...rest} round />
            )))}
          </div>
          {subtitle && (
            <Typography variant="para.sm:body">
              {subtitle}
            </Typography>
          )}
        </div>
      )}
      {type === 'wizard' && Boolean(steps) && (
        <>
          <Spacer y="sm" />
          <Steps steps={steps} selected={selectedStep} />
        </>
      )}
      <div className={cls('Dialog-content', { standalone, noPadding, type })}>
        {children}
      </div>
      {!hideFooter && (
        <div className="Dialog-footer">
          <Linear align="center" gap="lg" orientation="horizontal" width="100pr">
            {footer.left}
          </Linear>
          <Linear align="center" gap="lg" justify="center" orientation="horizontal" width="100pr">
            {actions.map(({
              component,
              'data-testid': testId,
              disabled,
              onClick,
              state,
              title = '', /* eslint-disable-line no-shadow */
              tooltip,
              color = 'primary',
            }) => {
              if (component) return component;
              const isDisabled = state
                ? state !== 'ready'
                : Boolean(disabled || tooltip);
              const button = (
                <FormButton
                  className="Dialog-action"
                  color={color}
                  data-testid={testId}
                  key={title}
                  state={state || (isDisabled ? 'disabled' : 'ready')}
                  onClick={onClick}
                >
                  {title}
                </FormButton>
              );
              return (
                <Tooltip key={`${title}-${tooltip}`} title={tooltip}>
                  {button}
                </Tooltip>
              );
            })}
          </Linear>
          <Linear align="center" gap="lg" orientation="horizontal" width="100pr">
            {footer.right}
          </Linear>
        </div>
      )}
    </div>
  </DialogBase>
);

export default Dialog;
