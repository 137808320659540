/** @type {import('./mapKeyValues').default} */
const mapKeyValues = function mapKeyValues(arrayOrObject, func) {
  if (Array.isArray(arrayOrObject)) {
    return arrayOrObject.reduce((acc, item, index) => {
      const { key, val } = func({ key: index, val: item }, acc);
      acc[key] = val;
      return acc;
    }, {});
  }
  return Object.entries(arrayOrObject).reduce((acc, [key, val]) => {
    const { key: newKey, val: newValue } = func({ key, val }, acc);
    acc[newKey] = newValue;
    return acc;
  }, {});
};

export default mapKeyValues;
