import { memo } from 'react';
import isEqual from './isEqual';

/**
 * Creates a memoized version of a React component with deep equality comparison
 * @template {object} P - Props type for the component
 * @param {import('react').ComponentType<P>} Component - React component to memoize
 * @returns {import('react').MemoExoticComponent<import('react').ComponentType<P>>} Memoized component
 */
const deepMemo = (Component) => memo(Component, isEqual);

export default deepMemo;
