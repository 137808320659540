import React from 'react';
import cls from '../helpers/cls';
import './Spacer.css';

/**
 * A component that creates horizontal or vertical spacing
 * @param {Object} props
 * @param {boolean} [props.flex] - If true, the spacer will grow to fill available space
 * @param {'3xs'|'2xs'|'xs'|'sm'|'md'|'lg'|'xl'|'2xl'|'3xl'} [props.x] - Horizontal spacing size
 * @param {'3xs'|'2xs'|'xs'|'sm'|'md'|'lg'|'xl'|'2xl'|'3xl'} [props.y] - Vertical spacing size
 * @returns {JSX.Element}
 */
const Spacer = (props) => {
  const { flex, x, y } = props;
  const className = cls('Spacer', { flex, x, y });
  return <div aria-hidden="true" className={className} />;
};

export default Spacer;
