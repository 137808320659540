/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */

import React, { forwardRef } from 'react';
import { createPortal } from 'react-dom';

import cls from '../helpers/cls';
import transition from '../helpers/transition';

import './Drawer.css';

const Drawer = forwardRef(({
  children,
  className,
  onClose,
  onOpen,
  open,
  position = 'fixed',
  round,
  side,
  size = 'sm',
  style,
  timeout = 300,
  ...props
}, ref) => {
  const content = (
    <div
      className={cls(
        'Drawer-content',
        {
          open,
          position,
          round,
          side,
          size,
        },
        className,
      )}
      {...props}
      onClick={(event) => event.stopPropagation()}
      ref={ref}
      style={{
        ...style,
        transition: transition(['transform', 'width'], timeout),
      }}
    >
      {children}
    </div>
  );

  if (position === 'relative') return content;

  const drawer = (
    <div
      className={cls('Drawer', { open, position })}
      onClick={onClose}
      style={{ transition: transition(['opacity', 'visibility'], timeout) }}
    >
      {content}
    </div>
  );

  // unlike dialogs, multiple drawers should not be open at a time
  // so they can be pre-rendered in portals and stay below dialogs
  return createPortal(drawer, document.body);
});

export default Drawer;
