/* eslint-disable react/button-has-type */

import React, { useEffect, useRef } from 'react';
import cls from '../helpers/cls';

import CircularProgress from './CircularProgress';

import './FormButton.css';

const State = ['disabled', 'ready', 'waiting'].reduce((acc, key) => ({ ...acc, [key]: key }), {});

const FormButton = (props) => {
  const {
    className: classes,
    color = 'primary',
    size = 'md',
    children,
    disabled = false,
    state = disabled ? State.disabled : State.ready,
    type = 'button',
    width,
    ...rest
  } = props;

  const className = cls(
    'FormButton',
    {
      color,
      size,
      state,
      width,
    },
    classes,
  );

  const ref = useRef(null);

  useEffect(() => {
    const repaint = (event) => {
      const element = ref.current;
      if (!element) return;

      const parental = element.parentNode.getBoundingClientRect();
      const boundary = element.getBoundingClientRect();
      const correctX = event.clientX - parental.left - boundary.width / 2;
      const correctY = event.clientY - parental.top - boundary.height / 2;
      element.style.transform = `translate(${correctX}px, ${correctY}px)`;
    };

    window.addEventListener('pointermove', repaint);

    return () => window.removeEventListener('pointermove', repaint);
  }, []);

  return (
    <button
      {...rest}
      className={className}
      disabled={state !== State.ready}
      type={type}
    >
      {state === State.waiting && (
        <CircularProgress className="FormButton-spinner" color="canvas" size={20} />
      )}
      <div className="FormButton-content">
        {children}
      </div>
      <div className="FormButton-hoverEffectBlurWrapper">
        <div className="FormButton-hoverEffect" ref={ref} />
      </div>
    </button>
  );
};

export default FormButton;
