import React from 'react';
import Linear from 'lib-frontend-shared/src/components/Linear';
import PosterView from 'lib-frontend-shared/src/components/PosterView';
import PageNotFoundPoster from 'lib-frontend-shared/src/assets/images/poster/page-not-found.svg?react';

const PageNotFound = () => (
  <Linear width="100Pr" style={{ height: 'calc(100vh - 140px)' }} justify="center" align="center">
    <PosterView
      Poster={PageNotFoundPoster}
      header="Page Not Found"
    />
  </Linear>
);

export default PageNotFound;
