import { camelCase, upperFirst } from 'lodash';

/**
 * Short-hand utility function for creating [PEM naming convention](https://gist.github.com/Munawwar/9458a153f873ad7f22f9a86a1cf3aeec)
 * style CSS class names.
 * It will output a prefix class on it's own + modifiers classes + additionalClasses specified
 * @param {string} prefix `Component` or `Component-element` as prefix
 * @param {{ [modifier: string]: string|boolean }} [modifiers] e.g. { color: 'red', disabled: true }
 * @param  {...string} [additionalClasses]
 * @returns {string} e.g. cls('Switch', { color: 'red', disabled: true }, 'Class1', 'Class2')
 * -> `Switch Switch--colorRed Switch--disabled Class1 Class2`
 */
function cls(prefix, modifiers, ...additionalClasses) {
  return [
    ...additionalClasses.filter(Boolean),
    prefix,
    ...Object.entries(modifiers || {})
      .map(([key, val]) => {
        if (val === true) return camelCase(key);
        if (val) return `${camelCase(key)}${upperFirst(camelCase(val))}`;
        return val ? camelCase(val) : val;
      })
      .filter(Boolean)
      .map((prop) => `${prefix}--${prop}`),
  ].join(' ');
}

export default cls;
