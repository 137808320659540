/* eslint-disable jsx-a11y/no-noninteractive-tabindex */

import React, { forwardRef, useImperativeHandle, useRef } from 'react';

import cls from '../helpers/cls';

import './FocusTrap.css';

const FocusTrap = forwardRef(({ className, ...props }, ref) => {
  const headRef = useRef(null);
  const tailRef = useRef(null);

  useImperativeHandle(ref, () => ({
    focus: () => {
      headRef.current?.focus();
    },
  }));

  const head = (
    <div
      className={cls('FocusTrap-head', {})}
      {...props}
      ref={headRef}
      tabIndex="-1"
    />
  );

  const tail = (
    <div
      className={cls('FocusTrap-tail', {})}
      ref={tailRef}
      tabIndex="-1"
    />
  );

  return (
    <div className={cls('FocusTrap', {}, className)}>
      <div onFocus={() => tailRef.current?.focus()} tabIndex="0" />
      {head}
      {tail}
      <div onFocus={() => headRef.current?.focus()} tabIndex="0" />
    </div>
  );
});

export default FocusTrap;
