import React from 'react';

// FIXME: This is a very basic tooltip. Improve to allow fancy titles
const Tooltip = ({ children, title, ...rest }) => {
  if (!title) return children;
  return (
    <span title={title} {...rest}>
      {children}
    </span>
  );
};

export default Tooltip;
