/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */

import React, { useEffect, useRef, useState } from 'react';

import { createPortal } from 'react-dom';

import cls from '../helpers/cls';
import transition from '../helpers/transition';

import FocusTrap from './FocusTrap';
import Form from './Form';

import './DialogBase.css';

const DialogBase = ({
  children,
  className,
  onClose,
  onKeyDown,
  onSubmit,
  open: extOpen,
  size = 'md',
  style,
  timeout = 100,
  ...rest
}) => {
  const [open, setOpen] = useState(false);
  const [openDelayed, setOpenDelayed] = useState(open);

  useEffect(() => {
    // if dialog is mounted only when open = true
    // we will internally delay the transition to
    // ensure correct fade-in animation of dialog
    requestAnimationFrame(() => requestAnimationFrame(() => {
      setOpen(extOpen);
    }));
  }, [extOpen]);

  useEffect(() => {
    const timer = setTimeout(() => setOpenDelayed(open), timeout);
    return () => clearTimeout(timer);
  }, [open, timeout]);

  const focusTrap = useRef(null);
  const [lastFocused, setLastFocused] = useState(null);

  const refocus = () => requestAnimationFrame(() => {
    if (document.activeElement !== focusTrap.current) {
      focusTrap.current?.focus();
    }
  });

  useEffect(() => {
    if (open) {
      setLastFocused(document.activeElement);
      refocus();
    } else {
      lastFocused?.focus();
    }
  }, [lastFocused, open]);

  const dialog = (
    <dialog
      className="DialogBase"
      open={open}
      onClick={(event) => {
        if (event.target === event.currentTarget) {
          refocus(event);
          return onClose?.(event);
        }
        return undefined;
      }}
      onKeyDown={onKeyDown}
      style={{ transition: transition(['opacity', 'visibility'], timeout) }}
    >
      <div
        className={cls('DialogBase-card', { open, size }, className)}
        {...rest}
        style={{
          ...style,
          transition: transition(['box-shadow', 'transform', 'visibility'], timeout),
        }}
      >
        <FocusTrap ref={focusTrap}>
          <Form className={cls('DialogBase-form', {})} onSubmit={onSubmit}>
            {children}
          </Form>
        </FocusTrap>
      </div>
    </dialog>
  );

  // conditionally render portals so recently opened dialogs render over prior ones
  return createPortal(extOpen || openDelayed ? dialog : null, document.body);
};

export default DialogBase;
